.app-bar-logo {
    /* content:url('./logos/logo_digital_weather-nobg-cropped.png'); */
    content: url("./logos/logo_digital_weather_v2.png");
    max-height: 50px;
    margin-top: 10px;
}

/* @media {
    max-width: unset;
} */

@media (max-width: 450px) {
    .app-bar-logo {
        content: url("./logos/logo_digital_weather_v2_cropped.png");
        margin-top: 10px;
    }
}

html {
    text-align: left;
    align-items: center;
    font-size: 14px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    color: #000;
    margin: 0;
    padding: 0;
    /* height: 100%; */
    /* height: 90vh; */
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    /* max-height: 100%; */
    float: center;
    width: 100%;
}

.page-container {
    max-width: unset !important;
    /* max-width: 1200px; */
    /* height: 90vh !important; */
    margin: 0 !important;
    padding: 0 !important;
}

.module-page {
    max-width: 1200px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: 24px;
    padding-right: 24px;
}

.input-box {
    /* width: 342; */
    width: 100%;
    float: right;
    text-align: center;
}

.logo-img {
    max-width: 65%;
}

.authentication-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 95vh;
}

.authentication-paper {
    width: 50%;
    padding: 32px;
}

code {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 0px;
    font-family: Consolas, monospace;
    font-size: 14px;
    padding: 0px 1px;
}

.module-guide-text {
    text-align: justify;
    line-height: 16pt;
}

.map {
    z-index: 0;
    height: 100%;
    width: 100%;
    /* height: calc(100% - 64px); */
    /* width: 75%; */
    /* position: absolute !important; */
    /* top: 64px; */
    /* left: 0px; */
    /* bottom: 0px; */
    /* canvas,
    .mapboxgl-canvas {
        height: 100%;
    } */
}

.mapboxgl-ctrl-scale {
    /* max-width: 200px !important; */
    font-size: 14 !important;
    border: 2px solid #000000 !important;
    border-top: 0px !important;
    background-color: rgba(255, 255, 255, 0.445) !important;
}

/* .mapboxgl-ctrl-group {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .mapboxgl-ctrl-compass {
        .mapboxgl-ctrl-icon {
            scale: 1.5;
        }
    }
} */

.document-container {
    /* box-sizing: border-box; */
    z-index: 1;
    height: calc(100% - 64px);
    width: 25%;
    position: absolute !important;
    top: 64px;
    right: 0px;
    overflow: auto;
}

.map-layer-menu {
    opacity: 0.8;
    z-index: 1;
    position: absolute;
    margin-left: 10px;
    margin-top: 10px;
    top: 64px;
    left: 0px;
    overflow: auto;
    border-radius: 4px;
    /* min-width: 270px;
    max-width: 270px; */
    min-width: 300px;
    max-width: 300px;
    text-align: center;
    text-justify: center;
}

.progress {
    display: flex;
    justify-content: "center";
    align-items: center;
    padding: 100px;
}

ol {
    margin-left: -13pt;
}

.sidebar {
    background-color: rgb(35 55 75 / 90%);
    color: #fff;
    /* padding: 6px 12px; */
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 10px; /* Match the map's top positioning */
    left: 50%; /* Center the sidebar */
    transform: translateX(-50%); /* Offset the left positioning to truly center */
    padding: 9.5px;
    border-radius: 4px;
    width: 310px; /* Adjust based on your sidebar width */
    text-align: center;
  }

.map-config {
    display: flex;
    align-items: flex-start; /* Align children to the top */
    justify-content: center; /* Center children horizontally */
    /* height: 100vh; */
    /* position: relative; */

    height: calc(100% - 64px);
    width: 75%;
    position: absolute !important;
    top: 64px;
    left: 0px;
    bottom: 0px;
}

.bathymetry-legend {
    color: #fff;
    z-index: 1;
    position: absolute;
    top: calc(100% - 58px);
    left: 50%;
    transform: translateX(-50%);
    padding: 5.5px;
    border-radius: 4px;
    width: 600px; /* Adjust based on your sidebar width */
    text-align: center;
}

.mopo-table th, td {
    border: 1px solid white;
    padding-left: 2px;
    padding-right: 2px;
 }

.mopo-table th {
    max-height: 200px;
    overflow-x: clip;
    overflow-y: clip;
    /* padding: 0px; */
    padding-top: 2px;
    padding-bottom: 2px;
}

.mopo-table tr {
    border-top: solid 1px #ffffff;
    border-bottom: solid 1px #ffffff;
 }

 /* .mopo-table tr:hover{
    border-top: solid 1px #000 !important;
    border-bottom: solid 1px #000 !important;
 } */

.mopo-table-activity-id {
    width: 60px;
    text-align: center;
}

.mopo-table-activity-label {
    width: 486px;
}

.mopo-table-condition-col {
    max-width: 30px !important;
    min-width: 30px !important;
    text-align: center;
}

[data-tag-worstappliedpermission=N] {
    background-color: rgba(252, 28, 28, 1);
}

[data-tag-worstappliedpermission=R] {
    background-color: rgba(251, 209, 3, 1);
}

[data-tag-worstappliedpermission=Y] {
    background-color: rgb(53, 171, 49);
    /* rgb(57, 151, 53) was prettier but provided insufficient contrast on client's monitor*/
}

[data-tag-permission=N][data-tag-current=true] {
    background-color: rgba(252, 28, 28, 1);
}

[data-tag-permission=R][data-tag-current=true] {
    background-color: rgba(251, 209, 3, 1);
}

[data-tag-permission=Y][data-tag-current=true] {
    background-color: rgba(53, 171, 49, 1);
}



[data-tag-permission=N][data-tag-current=false] {
    background-color: rgba(252, 28, 28, 0.4);
}

[data-tag-permission=R][data-tag-current=false] {
    background-color: rgba(251, 209, 3, 0.4);
}

[data-tag-permission=Y][data-tag-current=false] {
    background-color: rgba(53, 171, 49, 0.4);
}


[data-tag-appliedcondition=true] {
    background-color: rgb(197, 197, 197);
}

[data-tag-appliedcondition=true][data-tag-worstappliedconditiontype=N] {
    background-color: rgba(252, 28, 28, 1);
}

[data-tag-appliedcondition=true][data-tag-worstappliedconditiontype=R] {
    background-color: rgba(251, 209, 3, 1);
}

[data-tag-appliedcondition=true][data-tag-worstappliedconditiontype=Y] {
    background-color: rgba(53, 171, 49, 1);
}

[data-is-fetched=false] {
    color: grey;
    
}
 